ul.smothscroll {
    position: fixed;
    bottom: 15px;
    right:30px;
    list-style: none;
    z-index: 999;
}
ul.smothscroll a {
    width: 50px;
    height: 50px;
    background:rgba(192, 80, 202, 0.6);
    color: #fff;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 47px;
    border:1px solid rgb(127, 30, 130);
    transition: all .3s;
}
ul.smothscroll a:hover{
    background:rgb(102, 30, 130);
}